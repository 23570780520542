import { Component, OnInit, Input, OnChanges, ElementRef, Output, EventEmitter } from '@angular/core';
import { SortDirection } from '../../models/sort.model';
import {IDomainModel} from "../../../../../models/domain.model";

@Component({
  selector: 'app-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.scss']
})
export class SortIconComponent implements OnInit, OnChanges {
  @Input() column: string;
  @Input() data?: IDomainModel[];
  @Input() activeColumn: string;
  @Input() activeDirection: SortDirection;
  @Output() sort: EventEmitter<string> = new EventEmitter<string>();
  isActive = false;

  constructor(private el: ElementRef) { }

  ngOnChanges(): void {
    const parent = this.el.nativeElement.parentElement;
    if (!parent) {
      return;
    }

    // Load css classes
    parent.classList.add('sortable');
    parent.classList.remove('sortable-active');
    if (this.column === this.activeColumn) {
      parent.classList.add('sortable-active');
    }

    // load icons
    this.isActive = this.column === this.activeColumn;
  }

  ngOnInit(): void {
    const parent = this.el.nativeElement.parentElement as Element;
    if (!parent) {
      return;
    }

    parent.addEventListener('click', () => {
      this.sort.emit(this.column);
      this.sortData();
    });
  }

  private sortData() {
    if (this.data && Array.isArray(this.data) && this.data.length > 0) {
      this.data.sort((a, b) => {
        const valueA = this.getColumnValue(a);
        const valueB = this.getColumnValue(b);

        // Сравниваем значения
        if (!valueA && !valueB) {
          return 0;
        } else if (!valueA) {
          return 1;
        } else if (!valueB) {
          return -1;
        } else {
          const comparison = valueA.localeCompare(valueB);
          return this.activeDirection === 'asc' ? comparison : -comparison;
        }
      });
    }
  }

  private getColumnValue(item: any): string {
    switch (this.column) {
      case 'ip':
        return item['ip'] || '';
      case 'dns':
        return item.whois?.dns?.[0] || '';
      case 'registrar_name':
        return item.whois?.registrar_name || '';
      case 'h1':
        return item.whois?.h1 || '';
      default:
        return '';
    }
  }

}
