import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ErrorService} from './error.service';
import {SelectBaseService} from './select-base.service';
import {Observable} from 'rxjs';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

    endpoint = 'https://zlinkcheck.com/api';
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    constructor(
        private ERROR: ErrorService,
        private SELECTBASE: SelectBaseService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cloneObject: any = {
            headers: this.prepareHeaders(req),
            url: (!req.headers.get('customlink') ? this.assignRequestURL(req) : req.url)
        }
        const nextRequest = req.clone(
            cloneObject
        )

        return next
            .handle(nextRequest)
            .pipe(this.ERROR.catch);
    }

    private prepareHeaders(request: HttpRequest<any>) {

        let headers: HttpHeaders = request.headers;
        const company = this.SELECTBASE.getBaseFromLocalStorage();


        if (headers.has('customlink')) {
            headers = headers.delete('customlink');
            return headers;
        }

        if (!headers.get('no-auth')) {
            const auth = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
            headers = headers.append('token', 'Bearer ' + auth);
            if (!!company) {
                headers = headers.append('company', company);
            }
        }

        if (headers.get('email-auth')) {
            const auth = JSON.parse(localStorage.getItem('messagesToken'));
            headers = headers.append('token', 'Bearer ' + auth);
            if (!!company) {
                headers = headers.append('company', company);
            }
        }

        if (!headers.get('content-type')) {
            headers = headers.append('content-type', 'application/json');
        }
        if (headers.has('no-type')) {
            headers = headers.delete('no-type');
            headers = headers.delete('content-type');
        }

        return headers;
    }

    private assignRequestURL(request: HttpRequest<any>): string {
        return `${this.endpoint}${request.url}`;
    }
}
