import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject, of, throwError} from 'rxjs';
import {map, catchError, switchMap, finalize, tap} from 'rxjs/operators';
import {UserModel} from '../_models/user.model';
import {AuthModel} from '../_models/auth.model';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {GetUserSuccess, UserLogout} from '../../../store/actions/user.actions';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    // private fields
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserModel>;
    isLoadingSubject: BehaviorSubject<boolean>;

    constructor(
        private router: Router,
        private STORE: Store<any>,
        private HTTP: HttpClient,
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.isLoading$ = this.isLoadingSubject.asObservable();
    }

    private setNoAuthHeader(): HttpHeaders {
      let header = new HttpHeaders();
      header = header.set('no-auth', 'true');
      return header;
    }

    // public methods
    login(username: string, password: string): Observable<any> {
        this.isLoadingSubject.next(true);
        const notFoundError = new Error('Not Found');
        if (!username || !password) {
            return of(notFoundError);
        }

        const user = {username, password};
        return this.HTTP.post<any>('/profile/login', user, {headers: this.setNoAuthHeader()})
            .pipe(
            map((auth: AuthModel) => {
                const result = this.setAuthFromLocalStorage(auth);
                return result;
            }),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            tap(() => this.router.navigate(['/dashboard'])),
            tap(() => this.isLoadingSubject.next(false))
        );
    }

    getUserByToken(): Observable<UserModel | any> {
        const auth = this.getAuthFromLocalStorage();

        if (!auth) {
            this.STORE.dispatch(new UserLogout());
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.HTTP.get('/profile')
            .pipe(
                map(user => {
                    this.STORE.dispatch(new GetUserSuccess(user || {}));
                    if (user) {
                    } else {
                        this.STORE.dispatch(new UserLogout());
                    }
                    this.isLoadingSubject.next(false);
                    return user;
                }),
                catchError((e) => {
                    this.isLoadingSubject.next(false);
                    this.STORE.dispatch(new UserLogout());
                    return throwError(e);
                })
            )

    }

    // need create new user then login
    // registration(user: UserModel): Observable<any> {
    //   this.isLoadingSubject.next(true);
    //   return this.authHttpService.createUser(user).pipe(
    //     map(() => {
    //       this.isLoadingSubject.next(false);
    //     }),
    //     switchMap(() => this.login(user.email, user.password)),
    //     catchError((err) => {
    //       console.error('err', err);
    //       return of(undefined);
    //     }),
    //     finalize(() => this.isLoadingSubject.next(false))
    //   );
    // }

    registration(user: UserModel): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.HTTP.post<UserModel>('/profile/register', user, {headers: this.setNoAuthHeader()}).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login(user.username, user.password)),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    // forgotPassword(email: string): Observable<boolean> {
    //     this.isLoadingSubject.next(true);
    //     return this.authHttpService
    //         .forgotPassword(email)
    //         .pipe(finalize(() => this.isLoadingSubject.next(false)));
    // }

    // private methods
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        if (auth && auth.accessToken) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth.accessToken));
            return true;
        }
        return false;
    }

    public getAuthFromLocalStorage(): string {
        try {
            const authData = JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    public logout(): void {
       localStorage.clear();
       this.STORE.dispatch(new UserLogout());
    }
}
