import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {UserActionsTypes, UserLogout, UserLogoutSuccess} from '../actions/user.actions';
import {catchError, delay, map} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable()
export class UserEffects {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    constructor(
        private actions$: Actions,
        private STORE: Store<any>,
        private ROUTER: Router,
    ) {

    }

    logOut$ = createEffect(() => this.actions$.pipe(
        ofType<UserLogout>(UserActionsTypes.USER_LOGOUT),
        map(() => {
            localStorage.removeItem(this.authLocalStorageToken);
            this.ROUTER.navigate(['/auth']);

            of(true)
                .pipe(delay(400))
                .subscribe(() => {
                    //this.STORE.dispatch(new ClearLinks());
                })
            return new UserLogoutSuccess();
        }),
        catchError(() => EMPTY)
    ));
}
