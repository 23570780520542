import {createFeatureSelector, createSelector, select} from "@ngrx/store";
import {pipe} from "rxjs";
import {map} from "rxjs/operators";
import {IUsersState} from "../../models/user.model";
import {usersActions, usersActionsTypes} from "../actions/users.actions";
import {logger} from "codelyzer/util/logger";

const UsersInitialState: IUsersState = {
    users: null
};

export function UsersReducer(state: IUsersState = UsersInitialState, action: usersActions) {
    switch (action.type) {
        case usersActionsTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload
            };
        default:
            return state;
    }
}

const getUsersState = createFeatureSelector<IUsersState>('users');

export const getUsers = createSelector(getUsersState, (state: IUsersState) => state.users);

export const getUser = (id: string) =>
    createSelector(getUsersState, (state: IUsersState) => state.users.find(u => u._id === id));

export const usersPipe = pipe(
    select(getUsers),
    map(res => res || [])
);