import {Action} from "@ngrx/store";

export enum serversActionsTypes {
    GET_SERVERS_SUCCESS= 'GET_SERVERS_SUCCESS',
}

export class GetServersSuccess implements Action {
    readonly type = serversActionsTypes.GET_SERVERS_SUCCESS;
    constructor(public payload: any) {
    }
}

export type serversActions = GetServersSuccess;
