import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {IUsersState} from '../../models/user.model';
import {IServerState} from '../../models/server';
import {serversActions, serversActionsTypes} from '../actions/server.action';

const ServersInitialState: IServerState = {
    servers: null
};

export function ServersReducer(state: IServerState = ServersInitialState, action: serversActions) {
    switch (action.type) {
        case serversActionsTypes.GET_SERVERS_SUCCESS:
            return {
                ...state,
                servers: action.payload,
            };
        default:
            return state;
    }
}

const getServersState = createFeatureSelector<IServerState>('servers');

export const getServers = createSelector(getServersState, (state: IServerState) => state.servers);

export const getServer = (id: string) =>
    createSelector(getServersState, (state: IServerState) => state.servers.find(s => s.server_id === id));

export const serversPipe = pipe(
    select(getServers),
    map(res => res || [])
);
