import {IDomainsState} from "../../models/domain.model";
import {createFeatureSelector, createSelector, select} from "@ngrx/store";
import {pipe} from "rxjs";
import {map} from "rxjs/operators";
import {ICfTrafficState} from "../../models/cfTraffic.model";
import {cfTrafficActions, cfTrafficActionsTypes} from "../actions/cfTraffic.actions";

const TrafficsInitialState: ICfTrafficState = {
    cftraffics: null
};

export function CfTrafficReducer(state: ICfTrafficState = TrafficsInitialState, action: cfTrafficActions) {
    switch (action.type) {
        case cfTrafficActionsTypes.GET_CFTRAFFIC_SUCCESS:
            return {
                ...state,
                cftraffics: action.payload,
            };
        default:
            return state;
    }
}

const getTrafficsState = createFeatureSelector<ICfTrafficState>('cftraffics');

export const getTraffics = createSelector(getTrafficsState, (state: ICfTrafficState) => state.cftraffics);

// export const getDomain = (id: string) =>
//     createSelector(getDomainsState, (state: IDomainsState) => state.domains.find(d => d._id === id));

// export const domainsPipe = pipe(
//     select(getDomains),
//     map(res => res || [])
// );
