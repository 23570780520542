import {createFeatureSelector, createSelector, select} from "@ngrx/store";
import {pipe} from "rxjs";
import {map} from "rxjs/operators";
import {IUsersExtraState} from "../../models/user.model";
import {usersExtraActions, usersExtraActionsTypes} from "../actions/usersextra.actions";
import {logger} from "codelyzer/util/logger";

const UsersExtraInitialState: IUsersExtraState = {
    users: []
};

export function UsersExtraReducer(state: IUsersExtraState = UsersExtraInitialState, action: usersExtraActions) {
    switch (action.type) {
        case usersExtraActionsTypes.GET_USERS_EXTRA_SUCCESS:
            return {
                ...state,
                users: action.payload
            };
        default:
            return state;
    }
}

const getUsersExtraState = createFeatureSelector<IUsersExtraState>('usersextra');

export const getUsersExtra = createSelector(getUsersExtraState, (state: IUsersExtraState) => state);

export const usersExtraPipe = pipe(
    select(getUsersExtra),
    map(res => res || {users: []})
);