import {Action} from "@ngrx/store";

export enum cfTrafficActionsTypes {
    GET_CFTRAFFIC_SUCCESS= 'GET_CFTRAFFIC_SUCCESS',
}

export class GetCfTrafficSuccess implements Action {
    readonly type = cfTrafficActionsTypes.GET_CFTRAFFIC_SUCCESS;
    constructor(public payload: any) {
    }
}

export type cfTrafficActions = GetCfTrafficSuccess;
