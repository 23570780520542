import {IDomainModel, IDomainsState} from "../../models/domain.model";
import {domainsActions, domainsActionsTypes} from "../actions/domains.actions";
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {map} from 'rxjs/operators';

const DomainsInitialState: IDomainsState = {
    domains: null
};

export function DomainsReducer(state: IDomainsState = DomainsInitialState, action: domainsActions) {
    switch (action.type) {
        case domainsActionsTypes.GET_DOMAINS_SUCCESS:
            return {
                ...state,
                domains: action.payload,
            };
        case domainsActionsTypes.CHANGE_AHREFS_DATE:
            return {
                ...state,
                domains: state.domains.map(function (d){
                    let new_obj = {...d};
                    const ahrefs = {...new_obj.ahrefs};

                    let fail = false;
                    if(ahrefs?.history?.length && action.payload.start && action.payload.end){
                        let history_objects = d.ahrefs.history.filter((obj) => obj.date >= action.payload.start && obj.date <= action.payload.end);
                        if(history_objects.length){
                            let history_obj = history_objects[history_objects.length - 1];
                            ahrefs.keys_diff = d.ahrefs.keys - history_obj.keys;
                            ahrefs.traffic_diff = d.ahrefs.traffic - history_obj.traffic;
                            ahrefs.ref_domains_diff = d.ahrefs.ref_domains - history_obj.ref_domains; 
                        }else{
                            fail = true;
                        }
                    }else{
                        fail = true;
                    }
                    if(fail){
                        ahrefs.keys_diff = null;
                        ahrefs.traffic_diff = null;
                        ahrefs.ref_domains_diff = null; 
                    }
                    new_obj.ahrefs = ahrefs;
                    return new_obj;
                })
            };
        case domainsActionsTypes.CHANGE_INDEX_DATE:
            return {
                ...state,
                domains: state.domains.map(function (d){
                    let new_obj = {...d};

                    let fail = false;
                    if(new_obj.index_history?.length && action.payload.start && action.payload.end){
                        let history_objects = d.index_history.filter((obj) => obj.date >= action.payload.start && obj.date <= action.payload.end);
                        if(history_objects.length){
                            let history_obj = history_objects[history_objects.length - 1];
                            new_obj.index_diff = d.index_results - history_obj.index; 
                        }else{
                            fail = true;
                        }
                    }else{
                        fail = true;
                    }
                    if(fail){
                        new_obj.index_diff = null; 
                    }
                    return new_obj;
                })
            };
        default:
            return state;
    }
}

const getDomainsState = createFeatureSelector<IDomainsState>('domains');

export const getDomains = createSelector(getDomainsState, (state: IDomainsState) => state.domains);

export const getDomain = (id: string) =>
    createSelector(getDomainsState, (state: IDomainsState) => state.domains.find(d => d._id === id));

export const domainsPipe = pipe(
    select(getDomains),
    map(res => res || [])
);

export const unchecked30Pipe = (days: number): UnaryFunction<Observable<any>, Observable<IDomainModel[]>> => {
   return pipe(
        domainsPipe,
        map(domains => {
            const currentDate = Date.now();
            return domains.filter(d => {
                if (d.date.expire !== undefined) {
                    const date = new Date(d.date.expire.domain).getTime();
                    return date > currentDate && date - currentDate < (86400000 * days);
                }
            });
        })
    );
};
