import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {applicationsActions, applicationsActionsTypes} from '../actions/applications.action';
import {IApplicationsState} from '../../models/applications.model';

const ApplicationsInitialState: IApplicationsState = {
    applications: null
};

export function ApplicationsReducer(state: IApplicationsState = ApplicationsInitialState, action: applicationsActions) {
    switch (action.type) {
        case applicationsActionsTypes.GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                applications: action.payload,
            };
        default:
            return state;
    }
}

const getApplicationsState = createFeatureSelector<IApplicationsState>('applications');

export const getApplications = createSelector(getApplicationsState, (state: IApplicationsState) => state.applications);

export const getApplication = (id: string) =>
    createSelector(getApplicationsState, (state: IApplicationsState) => state.applications.find(s => s._id === id));

export const ApplicationsPipe = pipe(
    select(getApplications),
    map(res => res || [])
);
