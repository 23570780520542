import {Injectable} from "@angular/core";
import {OperatorFunction, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ErrorService {

    constructor(
        private TOASTR: ToastrService,
    ) {
    }

    catch: OperatorFunction<any, any> = catchError((error: HttpErrorResponse) => {
        if (error?.error.e) {
            this.TOASTR.error(error.error.e);
        }
        return throwError(error);
    })
}
