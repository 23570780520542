import {Action} from '@ngrx/store';

export enum cfmailsActionsTypes {
    GET_CFMAILS_SUCCESS = 'GET_CFMAILS_SUCCESS',
    CFMAIL_SUCCESS = 'CFMAIL_SUCCESS',
}

export class GetCFMailsSuccess implements Action {
    readonly type = cfmailsActionsTypes.GET_CFMAILS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class CFMailSuccess implements Action {
    readonly type = cfmailsActionsTypes.CFMAIL_SUCCESS;

    constructor(public payload: any) {
    }
}

export type cfmailsActions = GetCFMailsSuccess | CFMailSuccess;
