import {Action} from "@ngrx/store";
import {IWhitelistState, IWhitelistModel} from '../../models/whitelist.model';

export enum whitelistActionsTypes {
    GET_WHITELIST_SUCCESS = 'GET_WHITELIST_SUCCESS',
    REMOVE_WHITELIST = 'REMOVE_WHITELIST',
    ADD_WHITELIST = 'ADD_WHITELIST',
    EDIT_WHITELIST = 'EDIT_WHITELIST',
}

export class GetWhitelistSuccess implements Action {
    readonly type = whitelistActionsTypes.GET_WHITELIST_SUCCESS;

    constructor(public payload: IWhitelistState) {
    }
}

export class RemoveWhitelist implements Action {
    readonly type = whitelistActionsTypes.REMOVE_WHITELIST;

    constructor(public payload: string) {
    }
}

export class AddWhitelist implements Action {
    readonly type = whitelistActionsTypes.ADD_WHITELIST;

    constructor(public payload: IWhitelistState) {
    }
}

export class EditWhitelist implements Action {
    readonly type = whitelistActionsTypes.EDIT_WHITELIST;

    constructor(public payload: IWhitelistModel) {
    }
}

export type whitelistActions = GetWhitelistSuccess | RemoveWhitelist | AddWhitelist | EditWhitelist;
