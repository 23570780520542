import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'custom-toast',
    templateUrl: './custom-toast.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../../../../node_modules/ngx-toastr/toastr.css'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('600ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0)',
                    opacity: 0,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('600ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: false,
})
export class CustomToastComponent extends Toast {
    private touchStart = 0;
    private touched: boolean;
    private deltaX = 0;

    constructor(
        protected TOASTR: ToastrService,
        public toastPackage: ToastPackage,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
        super(TOASTR, toastPackage);
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart(e: TouchEvent): void {
        this.touched = true;
        this.touchStart = e.touches[0].pageX;
    }

    @HostListener('touchend', ['$event'])
    onTouchEnd(e: TouchEvent): void {
        this.touched = false;
        if (this.deltaX > 50) {
            this.remove();
        } else {
            this.renderer.setStyle(this.el.nativeElement, 'transform', 'translate3D(0, 0, 0)');
        }
    }

    @HostListener('touchmove', ['$event'])
    onTouchMove(e: TouchEvent): void {
        this.deltaX = e.touches[0].pageX - this.touchStart;
        this.renderer.setStyle(this.el.nativeElement, 'transform', 'translate3D(' + this.deltaX + 'px, 0, 0)');
    }

}
