import {cfactionsActions, cfactionsActionsTypes} from "../actions/cfactions.actions";
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {ICFActionsState} from "../../models/domain.model";
import {map} from 'rxjs/operators';

const CFActionsInitialState: ICFActionsState = {
    cf_domains: []
};

export function CFActionsReducer(state: ICFActionsState = CFActionsInitialState, action: cfactionsActions) {
    switch (action.type) {
        case cfactionsActionsTypes.GET_CFACTIONS_DOMAINS_SUCCESS:
            return {
                ...state,
                cf_domains: action.payload,
            };
        case cfactionsActionsTypes.CFACTION_SUCCESS:
            return {
                ...state,
                cf_domains: Array.from([...state.cf_domains, ...action.payload]
                    .reduce((m, o) => m.set(o._id, o), new Map)
                    .values()
                ),
            };
        default:
            return state;
    }
}

const getCFActionsState = createFeatureSelector<ICFActionsState>('cf_domains');

export const getCFActions = createSelector(getCFActionsState, (state: ICFActionsState) => state.cf_domains);

export const getCFDataItem = (id: string) =>
    createSelector(getCFActionsState, (state: ICFActionsState) => state.cf_domains.find(d => d._id === id));

export const cfactionsPipe = pipe(
    select(getCFActions),
    map(res => res || [])
);