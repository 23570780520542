import {Action} from "@ngrx/store";
import {ICFDataState, ICFDataModel} from '../../models/cfdata.model';

export enum cfdataActionsTypes {
    GET_CFDATA_SUCCESS = 'GET_CFDATA_SUCCESS',
    REMOVE_CFDATA = 'REMOVE_CFDATA',
    ADD_CFDATA = 'ADD_CFDATA',
    EDIT_CFDATA = 'EDIT_CFDATA',
}

export class GetCFDataSuccess implements Action {
    readonly type = cfdataActionsTypes.GET_CFDATA_SUCCESS;

    constructor(public payload: ICFDataState) {
    }
}

export class RemoveCFData implements Action {
    readonly type = cfdataActionsTypes.REMOVE_CFDATA;

    constructor(public payload: string) {
    }
}

export class AddCFData implements Action {
    readonly type = cfdataActionsTypes.ADD_CFDATA;

    constructor(public payload: ICFDataState) {
    }
}

export class EditCFData implements Action {
    readonly type = cfdataActionsTypes.EDIT_CFDATA;

    constructor(public payload: ICFDataModel) {
    }
}

export type cfdataActions = GetCFDataSuccess | RemoveCFData | AddCFData | EditCFData;
