import {Action} from '@ngrx/store';

export enum cfactionsActionsTypes {
    GET_CFACTIONS_DOMAINS_SUCCESS = 'GET_CFACTIONS_DOMAINS_SUCCESS',
    CFACTION_SUCCESS = 'CFACTION_SUCCESS',
}

export class GetCFActionsDomainsSuccess implements Action {
    readonly type = cfactionsActionsTypes.GET_CFACTIONS_DOMAINS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class CFActionSuccess implements Action {
    readonly type = cfactionsActionsTypes.CFACTION_SUCCESS;

    constructor(public payload: any) {
    }
}

export type cfactionsActions = GetCFActionsDomainsSuccess | CFActionSuccess;
