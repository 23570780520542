import {Action} from "@ngrx/store";

export enum companiesActionsTypes {
    GET_COMPANIES_SUCCESS= 'GET_COMPANIES_SUCCESS',
}

export class GetCompaniesSuccess implements Action {
    readonly type = companiesActionsTypes.GET_COMPANIES_SUCCESS;
    constructor(public payload: any) {
    }
}

export type companiesActions = GetCompaniesSuccess;