import {Action} from "@ngrx/store";

export enum domainsActionsTypes {
    GET_DOMAINS_SUCCESS = 'GET_DOMAINS_SUCCESS',
    CHANGE_AHREFS_DATE = 'CHANGE_AHREFS_DATE',
    CHANGE_INDEX_DATE = 'CHANGE_INDEX_DATE',
}

export class GetDomainsSuccess implements Action {
    readonly type = domainsActionsTypes.GET_DOMAINS_SUCCESS;
    constructor(public payload: any) {
    }
}

export class ChangeAhrefsDate implements Action {
    readonly type = domainsActionsTypes.CHANGE_AHREFS_DATE;
    constructor(public payload: any) {
    }
}

export class ChangeIndexDate implements Action {
    readonly type = domainsActionsTypes.CHANGE_INDEX_DATE;
    constructor(public payload: any) {
    }
}

export type domainsActions = GetDomainsSuccess | ChangeAhrefsDate | ChangeIndexDate;
