import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot, UrlTree, Router, CanActivateChild, CanActivate,
} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {getUser} from '../../../store/reducers/user.reducer';
import {map, tap} from 'rxjs/operators';
import {SelectBaseService} from '../../../services/select-base.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(
        private STORE: Store<any>,
        private ROUTER: Router,
        private SELECTBASE: SelectBaseService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const company = this.SELECTBASE.getBaseFromLocalStorage();
        return this.STORE.select(getUser)
            .pipe(map(u => {
                return !(!!u && (!!u._id || !!u.id))
                    ? this.ROUTER.parseUrl('/auth/login')
                    : !company && state.url !== '/company'
                        ? this.ROUTER.parseUrl('/company')
                        : true;
            }));
    }
}
