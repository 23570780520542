import {cfmailsActions, cfmailsActionsTypes} from "../actions/cfmails.actions";
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {ICFMailsState} from "../../models/cfmail.model";
import {map} from 'rxjs/operators';

const CFMailsInitialState: ICFMailsState = {
    cfmails: []
};

export function CFMailsReducer(state: ICFMailsState = CFMailsInitialState, action: cfmailsActions) {
    switch (action.type) {
        case cfmailsActionsTypes.GET_CFMAILS_SUCCESS:
            return {
                ...state,
                cfmails: action.payload,
            };
        case cfmailsActionsTypes.CFMAIL_SUCCESS:
            return {
                ...state,
                cfmails: Array.from([...state.cfmails, ...action.payload]
                    .reduce((m, o) => m.set(o._id, o), new Map)
                    .values()
                ),
            };
        default:
            return state;
    }
}

const getCFMailsState = createFeatureSelector<ICFMailsState>('cfmails');

export const getCFMails = createSelector(getCFMailsState, (state: ICFMailsState) => state.cfmails);

export const getCFMailsItem = (id: string) =>
    createSelector(getCFMailsState, (state: ICFMailsState) => state.cfmails.find(d => d._id === id));

export const cfmailsPipe = pipe(
    select(getCFMails),
    map(res => res || [])
);