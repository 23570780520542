import {whitelistActions, whitelistActionsTypes} from "../actions/whitelist.actions";
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {IWhitelistState} from '../../models/whitelist.model';
import {map} from 'rxjs/operators';

const WhitelistInitialState: any = {
    whitelist: null
};

export function WhitelistReducer(state: IWhitelistState = WhitelistInitialState, action: whitelistActions) {
    switch (action.type) {
        case whitelistActionsTypes.GET_WHITELIST_SUCCESS:
            return {
                ...state,
                whitelist: action.payload,
            };
		case whitelistActionsTypes.REMOVE_WHITELIST:
            return {
                ...state,
                whitelist: state.whitelist.filter(d => d._id !== action.payload),
            };
		case whitelistActionsTypes.ADD_WHITELIST:
            return {
                ...state,
                whitelist: [...state.whitelist, action.payload],
            };
		case whitelistActionsTypes.EDIT_WHITELIST:
            return {
                ...state,
                whitelist: state.whitelist.map(function (d){
					let new_obj = {
                        _id: d._id, 
                        domain: d.domain, 
                        add_date: d.add_date,
                        edit_date: d.edit_date,
                        author_id: d.author_id,
                        edit_author_id: d.edit_author_id,
                        comment: d.comment,
                    };
					if(new_obj._id == action.payload._id){
						new_obj.domain = action.payload.domain;
                        new_obj.add_date = action.payload.add_date;
                        new_obj.edit_date = action.payload.edit_date;
                        new_obj.author_id = action.payload.author_id;
                        new_obj.edit_author_id = action.payload.edit_author_id;
                        new_obj.comment = action.payload.comment;
					}
					return new_obj;
				})
            };
        default:
            return state;
    }
}

const getWhitelistState = createFeatureSelector<IWhitelistState>('whitelist');

export const getWhitelist = createSelector(getWhitelistState, (state: IWhitelistState) => state.whitelist);

export const getWhitelistItem = (id: string) =>
    createSelector(getWhitelistState, (state: IWhitelistState) => state.whitelist.find(d => d._id === id));

export const whitelistPipe = pipe(
    select(getWhitelist),
    map(res => res || [])
);