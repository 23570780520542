import {ICompanyModel, ICompanyState} from "../../models/company.model";
import {companiesActions, companiesActionsTypes} from "../actions/companies.actions";
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {map} from 'rxjs/operators';

const CompaniesInitialState: ICompanyState = {
    companies: null
};

export function CompaniesReducer(state: ICompanyState = CompaniesInitialState, action: companiesActions) {
    switch (action.type) {
        case companiesActionsTypes.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
            };
        default:
            return state;
    }
}

const getCompaniesState = createFeatureSelector<ICompanyState>('companies');

export const getCompanies = createSelector(getCompaniesState, (state: ICompanyState) => state.companies);

export const companiesPipe = pipe(
    select(getCompanies),
    map(res => res || [])
);
