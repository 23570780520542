import {Injectable} from "@angular/core";
import {environment} from 'src/environments/environment';

@Injectable()
export class SelectBaseService {

    private localStorageBase = `${environment.appVersion}-base`;

    constructor() {
    }

    getBaseFromLocalStorage(): string {
        try {
            const base = localStorage.getItem(this.localStorageBase);
            return base;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }
}
