import {Action} from "@ngrx/store";

export enum usersExtraActionsTypes {
    GET_USERS_EXTRA_SUCCESS= 'GET_USERS_EXTRA_SUCCESS',
}

export class GetUsersExtraSuccess implements Action {
    readonly type = usersExtraActionsTypes.GET_USERS_EXTRA_SUCCESS;
    constructor(public payload: any) {
    }
}

export type usersExtraActions = GetUsersExtraSuccess;
