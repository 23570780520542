import {cfdataActions, cfdataActionsTypes} from "../actions/cfdata.actions";
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {ICFDataState} from '../../models/cfdata.model';
import {map} from 'rxjs/operators';

const CFDataInitialState: any = {
    cfdata: null
};

export function CFDataReducer(state: ICFDataState = CFDataInitialState, action: cfdataActions) {
    switch (action.type) {
        case cfdataActionsTypes.GET_CFDATA_SUCCESS:
            return {
                ...state,
                cfdata: action.payload,
            };
		case cfdataActionsTypes.REMOVE_CFDATA:
            return {
                ...state,
                cfdata: state.cfdata.filter(d => d._id !== action.payload),
            };
		case cfdataActionsTypes.ADD_CFDATA:
            return {
                ...state,
                cfdata: [...state.cfdata, action.payload],
            };
		case cfdataActionsTypes.EDIT_CFDATA:
            return {
                ...state,
                cfdata: state.cfdata.map(function (d){
					let new_obj = {
                        _id: d._id, 
                        email: d.email,
                        key: d.key, 
                        author_id: d.author_id,
                        edit_author_id: d.edit_author_id,
                        domains: d.domains,
                        mails: d.mails, 
                        add_date: d.add_date,
                        edit_date: d.edit_date,
                        works: d.works,
                    };
					if(new_obj._id == action.payload._id){
						new_obj.email = action.payload.email;
                        new_obj.key = action.payload.key;
                        new_obj.author_id = action.payload.author_id;
                        new_obj.edit_author_id = action.payload.edit_author_id;
                        new_obj.domains = action.payload.domains;
                        new_obj.mails = action.payload.mails;
                        new_obj.add_date = action.payload.add_date;
                        new_obj.edit_date = action.payload.edit_date;
                        new_obj.works = action.payload.works;
					};
					return new_obj;
				})
            };
        default:
            return state;
    }
}

const getCFDataState = createFeatureSelector<ICFDataState>('cfdata');

export const getCFData = createSelector(getCFDataState, (state: ICFDataState) => state.cfdata);

export const getCFDataItem = (id: string) =>
    createSelector(getCFDataState, (state: ICFDataState) => state.cfdata.find(d => d._id === id));

export const cfdataPipe = pipe(
    select(getCFData),
    map(res => res || [])
);