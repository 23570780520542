import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot, UrlTree, Router, CanActivateChild,
} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import { userPipe} from '../../../store/reducers/user.reducer';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotAuthGuard implements CanActivateChild {
    constructor(
        private STORE: Store<any>,
        private ROUTER: Router,
    ) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.STORE.pipe(userPipe)
            .pipe(
                map(u => {
                    return (!u._id && !u.id) || this.ROUTER.parseUrl('/dashboard');
                }));
    }
}
