import {Injectable} from "@angular/core";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';

export interface IExcelOptions {
    headers?: string[],
    columns?: {number: number, width: number}[]
}

@Injectable({
    providedIn: 'root'
})
export class ExportExcelService {

    async generateExcel(data: string[][], options?: IExcelOptions) {

        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sharing Data');

        // Add Header Row
        if (options.headers && options.headers.length){

            const headerRow = worksheet.addRow(options.headers);

            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF00' },
                    bgColor: { argb: 'FF0000FF' }
                };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });

        }

        // Add Data and Conditional Formatting
        data.forEach(d => { worksheet.addRow(d) });

        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 10;
        worksheet.getColumn(3).width = 10;
        worksheet.getColumn(4).width = 10;
        worksheet.getColumn(5).width = 30;
        worksheet.getColumn(6).width = 10;
        worksheet.getColumn(7).width = 10;
        worksheet.getColumn(8).width = 10;
        worksheet.getColumn(9).width = 10;
        worksheet.getColumn(10).width = 10;
        worksheet.getColumn(11).width = 10;
        worksheet.getColumn(12).width = 10;
        worksheet.getColumn(13).width = 70;

        if (options.columns && options.columns.length){
            options.columns.forEach(o => {
                worksheet.getColumn(o.number).width = o.width
            })
        }

        worksheet.addRow([]);

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'ZlinkCheckShare.xlsx');
        });

    }
}
