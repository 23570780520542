import {IUserState, UserModel} from '../../modules/auth';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {pipe} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {UserActions, UserActionsTypes} from '../actions/user.actions';


const UserInitialState: IUserState = {
    user: null
}

export function UserReducer(state: IUserState = UserInitialState, action: UserActions): IUserState {

    switch (action.type) {
        case UserActionsTypes.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload
            };
        case UserActionsTypes.USER_LOGOUT:
            return {
                ...state,
                user: {}
            };
        default:
            return state;
    }
}

const getUserState = createFeatureSelector<IUserState>('user');

export const getUser = createSelector(getUserState, (state: IUserState) => state.user);

export const userPipe = pipe(
    select(getUser),
    filter(res => !!res),
);
