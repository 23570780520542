import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth';
// Highlight JS
import {HighlightModule, HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {SplashScreenModule} from './_metronic/partials/layout/splash-screen/splash-screen.module';
import {StoreModule} from '@ngrx/store';
import {AppEffects, AppReducers} from './store';
import {MainInterceptor} from './services/main.interceptor';
import {ErrorService} from './services/error.service';
import {SelectBaseService} from './services/select-base.service';
import {ReactiveFormsModule} from '@angular/forms';
import {ApiService} from './services/api.service';
import {ToastrModule} from 'ngx-toastr';
import {CustomToastComponent} from './components/custom-toast/custom-toast.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ApplicationsServise} from './services/applications.servise';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {EffectsModule} from "@ngrx/effects";
import {BlockScrollStrategy, Overlay, OverlayModule} from '@angular/cdk/overlay';
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY} from '@angular/material/autocomplete';
import {ExportExcelService} from './services/export-excel.service';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';

function appInitializer(authService: AuthService) {
    return () => {
        return new Promise((resolve) => {
            authService.getUserByToken().subscribe().add(resolve);
        });
    };
}

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
    return () => overlay.scrollStrategies.block();
}

@NgModule({
    declarations: [AppComponent, CustomToastComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        TranslateModule.forRoot(),
        HttpClientModule,
        MatDialogModule,
        HighlightModule,
        ClipboardModule,
        // environment.isMockEnabled
        //   ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        //     passThruUnknownUrl: true,
        //     dataEncapsulation: false,
        //   })
        //   : [],
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        ReactiveFormsModule,
        StoreModule.forRoot(AppReducers),
        EffectsModule.forRoot(AppEffects),
        ToastrModule.forRoot({
            timeOut: 3000,
            toastComponent: CustomToastComponent,
            preventDuplicates: true,
        }),
        MatFormFieldModule,
        MatSelectModule,
        OverlayModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    xml: () => import('highlight.js/lib/languages/xml'),
                    typescript: () => import('highlight.js/lib/languages/typescript'),
                    scss: () => import('highlight.js/lib/languages/scss'),
                    json: () => import('highlight.js/lib/languages/json')
                },
            },
        },
        ErrorService,
        ApiService,
        ApplicationsServise,
        SelectBaseService,
        ExportExcelService,
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: scrollFactory,
            deps: [Overlay],
        },
    ],
    bootstrap: [AppComponent],

})
export class AppModule {
}
