import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {GetApplicationsSuccess} from '../store/actions/applications.action';
import {getApplications} from '../store/reducers/applications.reducer';

@Injectable()

export class ApplicationsServise {
    regex = /https/;

    constructor(
        private HTTP: HttpClient,
        private STORE: Store<any>,
    ) {}

    addApp(body): Observable<any> {
        return this.HTTP.post('/apps/add', body)
            .pipe(
                tap(res => {
                    this.getApps().subscribe();
                })
            );
    }

    getApps(): Observable<any> {
        return this.HTTP.get('/apps/get_all')
            .pipe(tap(res => {
                this.STORE.dispatch(new GetApplicationsSuccess(res.success));
            }));
    }

    checkApps(): void {
        this.STORE.select(getApplications)
            .pipe(
                take(1),
                tap(res => {
                    if (!res) {
                        this.getApps().subscribe();
                    }
                })
            )
            .subscribe();
    }

    editApp(body): Observable<any> {
        return this.HTTP.patch('/apps/edit', body)
            .pipe(
                tap(res => {
                    this.getApps().subscribe();
                })
            );
    }

    deleteApps(ids: string[]): Observable<any>{
        return this.HTTP.post('/apps/delete', {id: ids})
            .pipe(
                tap(res => {
                    this.getApps().subscribe();
                })
            );
    }

    checkStatusApps(ids: string[]): Observable<any>{
        return this.HTTP.post('/apps/check_status', {id: ids})
            .pipe(
                tap(res => {
                    this.getApps().subscribe();
                })
            );
    }

}
