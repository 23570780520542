import {ActionReducerMap} from '@ngrx/store';
import {DomainsReducer} from './reducers/domains.reducer';
import {CompaniesReducer} from './reducers/companies.reducer';
import {UsersReducer} from './reducers/users.reducer';
import {UsersExtraReducer} from './reducers/usersextra.reducer'; 
import {UserReducer} from './reducers/user.reducer';
import {CfTrafficReducer} from './reducers/cfTraffic.reducer';
import {ServersReducer} from './reducers/servers.reducer';
import {ApplicationsReducer} from './reducers/applications.reducer'
import {WhitelistReducer} from './reducers/whitelist.reducer';
import {CFDataReducer} from './reducers/cfdata.reducer';
import {CFMailsReducer} from './reducers/cfmails.reducer';
import {CFActionsReducer} from './reducers/cfactions.reducer';
import {UserEffects} from './effects/user.effects';

export const AppReducers: ActionReducerMap<any, any> = {
        domains: DomainsReducer,
	companies: CompaniesReducer,
        users: UsersReducer,
        usersextra: UsersExtraReducer,
	user: UserReducer,
        cftraffics: CfTrafficReducer,
        servers: ServersReducer,
        applications: ApplicationsReducer,
	whitelist: WhitelistReducer,
	cfdata: CFDataReducer,
	cfmails: CFMailsReducer,
	cf_domains: CFActionsReducer,
};

export const AppEffects = [
	UserEffects,
];
