import {Action} from "@ngrx/store";

export enum usersActionsTypes {
    GET_USERS_SUCCESS= 'GET_USERS_SUCCESS',
}

export class GetUsersSuccess implements Action {
    readonly type = usersActionsTypes.GET_USERS_SUCCESS;
    constructor(public payload: any) {
    }
}

export type usersActions = GetUsersSuccess;
