import {Action} from '@ngrx/store';
import {UserModel} from '../../modules/auth';

export enum UserActionsTypes {
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',
    USER_LOGOUT = 'USER_LOGOUT',
    USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
}

export class GetUserSuccess implements Action {
    readonly type = UserActionsTypes.GET_USER_SUCCESS;
    constructor(public payload: UserModel) {}
}

export class UserLogout implements Action {
    readonly type = UserActionsTypes.USER_LOGOUT;

    constructor() {
    }
}

export class UserLogoutSuccess implements Action {
    readonly type = UserActionsTypes.USER_LOGOUT_SUCCESS;

    constructor() {
    }
}

export type UserActions = GetUserSuccess
    | UserLogout | UserLogoutSuccess;
