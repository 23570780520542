import {Action} from "@ngrx/store";

export enum applicationsActionsTypes {
    GET_APPLICATIONS_SUCCESS= 'GET_APPLICATIONS_SUCCESS',
}

export class GetApplicationsSuccess implements Action {
    readonly type = applicationsActionsTypes.GET_APPLICATIONS_SUCCESS;
    constructor(public payload: any) {
    }
}

export type applicationsActions = GetApplicationsSuccess;
